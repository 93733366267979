import { ProductCardSimple } from 'components/Product/ProductCard';
import { useProductSelector } from 'features';
import useProductCard from 'hooks/useProductCard';
import { memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import { IGroupingProducts } from 'types/ContentfulTypes';
import { shuffleArray } from 'utils/arrayUtil';
import Box from '../../../Box/Box';
import Flex from '../../../Flex/Flex';
import HeaderSimple from '../../../HeaderSimple/HeaderSimple';
import SkeletonCard from '../../../Skeleton/Card/SkeletonCard';
import ContentfulCallToAction from '../ContentfulCallToAction/ContentfulCallToAction';
import { StyledGrid, StyledLoadMoreButton } from './ContentfulProductTiles.styled';

interface ContentfulProductTilesProps {
  className?: string;
  content: IGroupingProducts;
  hideTitle?: boolean;
  pageSize?: number;
}

const ContentfulProductTiles = ({ className, content, hideTitle, pageSize = 15 }: ContentfulProductTilesProps) => {
  const { formatMessage } = useIntl();
  const { mapProductCardSimpleProps } = useProductCard();
  const [amountToLoad, setAmountToLoad] = useState(pageSize);

  const { callToAction, products: productSelector, randomizeProductsOrder, showSignings, title } = content.fields;
  const { id } = content.sys;

  const activeItems = productSelector?.fields?.products?.activeItems;

  const { data: products, isFetching } = useProductSelector({
    maxProducts: amountToLoad,
    selector: productSelector,
  });

  const randomizedProducts = randomizeProductsOrder ? shuffleArray(products) : products;

  return (
    <>
      {!hideTitle && title && (
        <Flex alignItems="center" flexWrap="wrap" gap={200} marginBottom={100}>
          <HeaderSimple marginBottom={0} title={title} titleTag="h2" titleType="h3" />
          <ContentfulCallToAction content={callToAction} />
        </Flex>
      )}

      <Flex flexDirection="column" gap={200}>
        <Box position="relative">
          <StyledGrid className={className}>
            {randomizedProducts?.map((product, index) => {
              const { signing, ...props } = mapProductCardSimpleProps({ product });

              return (
                <ProductCardSimple
                  {...props}
                  key={`ProductTileContentful-${product?.code}-${index}`}
                  signing={showSignings ? signing : undefined}
                />
              );
            })}
            {isFetching && [...Array(pageSize || 10)].map(() => <SkeletonCard key={id} id={id} />)}
          </StyledGrid>
        </Box>

        {activeItems?.length > amountToLoad && activeItems?.length > pageSize && (
          <StyledLoadMoreButton
            color="black"
            loading={isFetching}
            onClick={() => setAmountToLoad((prevAmount) => prevAmount + pageSize)}
            outlined
            size="large"
          >
            {formatMessage({
              id: 'view_all_deals_label',
            })}
          </StyledLoadMoreButton>
        )}
      </Flex>
    </>
  );
};

export default memo(ContentfulProductTiles, isEqual);
